<template>
  <div class="container" :style="backgroundDiv">
    <header>
      <div>
      <img src="../../assets/mikui.png"/>
      <span>蜜葵</span>
      </div>
      <p><span>北京星壳时代文化传媒有限公司</span><span>Beijing Star Shell Times Culture Media Co., Ltd.</span></p>
    </header>
    <div class="con">
      <div class="title">口播赚钱3部曲</div>
      <div class="img">
        <img src="../../assets/index_1.png" />
        <img src="../../assets/index_2.png" />
        <img src="../../assets/index_3.png" />
      </div>
      <div class="title">App介绍</div>
      <p>
        蜜葵（也叫达人Pick计划），众多内容达人主播up主都在用的变现APP
      </p>
      <p>众多内容创作者的密令带货首选平台，更值得信赖！</p>
      <p>
        专注于帮助短视频直播内容创作者简单高效变现，无论你是网红、达人、主播、up主还是自媒体等内容创作者，都能加入蜜葵达人Pick计划高效变现
      </p>
      <p>
        无门槛零投资的内容创作者轻创平台，官方输出干货课程素材，专业导师带路超易上手，自用赚钱，分享更赚钱，开启全民短视频轻创业新模式
      </p>
      <div class="title">基础服务</div>
      <p>蜜葵APP是为网红达人提供流量变现变现服务的APP，APP只接受达人注册。</p>
      <p>
        在认证通过后，可以帮助达人生成京东密令，在直播、视频号或者其它视频中进行口播推广。
      </p>
      <p>
        达人可邀请其他达人注册，作为邀请人将获得被邀请人的部分收益，平台支持三级邀请成。
      </p>
    </div>
    <footer>京ICP备2021004181号-1</footer>
  </div>
</template>

<script>
// import 'lib-flexible'

import { resetRem } from "@/assets/rem.js";
export default {
  data() {
    return {
      backgroundDiv: {
        backgroundImage: "url(" + require("../../assets/bg-h5.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
    };
  },
  created() {
    resetRem();
  },
  mounted() { document.title = "蜜葵"},
  methods: {},
};
</script>
<style scoped>
.container{
    overflow-y: scroll;
}

.container::-webkit-scrollbar{
  border:0
}
header {
  width: 100%;
  height: 2.346rem;
  background: #363636;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
header>div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
header p{
  margin:0;
  padding:0;
  padding-right: 0.32rem;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  width: 6.2rem
}
header img {
  width: 1.28rem;
  height: 1.28rem;
  margin-left: 0.32rem; 
 margin-right: 0.2933rem;
}
header span {
  font-family: PingFang-SC-Heavy;
  font-size: 0.64rem;
  color: #ddb383;
  letter-spacing: 0;
}
header p span{
font-size: 0.3733rem;
}
header p span:last-child{
  font-size: 0.24rem;
}
.con {
  padding-top: 2.346rem;
}
.title {
  padding-left: 0.64rem;
  margin-top: 0.64rem;
  margin-bottom: 0.1867rem;
  font-family: PingFangSC-Regular;
  font-size: 0.48rem;
  color: #ddb383;
  letter-spacing: 0;
}
p {
  margin-bottom: 0.64rem;
  opacity: 0.8;
  font-family: PingFangSC-Regular;
  font-size: 0.3733rem;
  color: #ddb383;
  letter-spacing: 0;
  text-align: justify;
  padding: 0 0.64rem;
}
.img {
  padding: 0 0.64rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.img img {
  opacity: 0.9;
  width: 2.6213rem;
  height: 2.56rem;
}
footer{
    font-family: PingFangSC-Regular;
font-size: 0.3733rem;
color: #FFFFFF;
letter-spacing: 0;
text-align: justify;
text-align: center;
margin-top:2rem;
margin-bottom: 1rem;
}
</style>